<template>
  <client-only>
    <div class="flex items-center h-auto w-full p-4 rounded bg-white-dark">
      <div class="flex">
        <NuxtImg
          :src="loggedIn ? '/infogram_auth.png' : '/infogram_no_auth.png'"
          width="180px"
          height="107px"
          class="hidden sm:block object-contain"
          loading="lazy"
        />
      </div>
      <div class="flex flex-col sm:ml-4 w-full sm:w-auto">
        <p class="text-gray-dark text-sm lg:text-lg mr-2 font-semibold">
          {{
            loggedIn
              ? $t('banners.privateContent.titleLoggedIn')
              : $t('banners.privateContent.titleLoggedOut')
          }}
        </p>
        <div class="flex justify-end lg:block whitespace-nowrap mt-2">
          <button
            id="btn-register-download"
            class="font-ubuntu font-bold text-sm lg:text-md underline mt-2 mr-2 lg:m-0"
            type="button"
            :class="loggedIn ? 'text-blue' : 'text-orange'"
            @click.stop.prevent="openPrivateContent"
          >
            {{
              loggedIn
                ? $t('banners.privateContent.linkLoggedIn')
                : $t('banners.privateContent.linkLoggedOut')
            }}
          </button>
          <icon v-if="loggedIn" name="pt-expand-from-corner" class="text-xl mt-2 text-blue" />
        </div>
      </div>
      <modal-wrapper
        name="privateContent"
        :width="md ? '80%' : '100%'"
        height="100%"
        @before-open="beforeOpenPrivateContent"
      >
        <div
          v-if="content.type === 'ComponentContentInfographic'"
          class="responsive-iframe h-screen"
        >
          <iframe
            v-lazy-load
            :src="content.external_id"
            scrolling="auto"
            frameborder="0"
            allowfullscreen
          />
        </div>
      </modal-wrapper>
    </div>
  </client-only>
</template>
<script>
import ModalWrapper from '@/components/ModalWrapper';
export default {
  name: 'PtPrivateContent',

  components: {
    ModalWrapper
  },

  props: {
    content: {
      type: Object,
      default: () => null
    }
  },

  setup(props) {
    const { md } = useBreakpoints();
    const store = useStore();
    const { gtmInfogramTracking } = useGtmEvents();
    const { $modal, $t } = useNuxtApp();

    const loggedIn = computed(() => store.state.auth.loggedIn);

    const openPrivateContent = () => {
      loggedIn
        ? $modal.open('privateContent')
        : $modal.open('register', { origin: 'btn-register-download' });
    };

    const beforeOpenPrivateContent = () => {
      // eslint-disable-next-line camelcase
      const { external_id } = props.content;
      gtmInfogramTracking(external_id, $t('banners.privateContent.titleLoggedOut'));
    };

    return {
      md,
      loggedIn,
      openPrivateContent,
      beforeOpenPrivateContent
    };
  }
};
</script>
