<template>
  <client-only>
    <div class="mt-4">
      <dl class="pt-collapsible box" role="reports">
        <div class="mb-4" :group-id="1">
          <dt class="pt-collapsible__title bg-blue-dark text-white">
            <button
              class="flex justify-between w-full p-4 items-center"
              :aria-expanded="expandedId === 1"
              :aria-controls="`${1}-content`"
              type="button"
              @click="toggleState()"
            >
              {{ $t('detail.reportVersions') }}
              <icon
                class="text-white"
                :name="expandedId === 1 ? 'pt-angle-up' : 'pt-angle-down'"
                aria-hidden="true"
              />
            </button>
          </dt>
          <dd
            :id="`${1}-content`"
            class="overflow-hidden h-0"
            role="region"
            :class="{ 'pt-expanded': expandedId === 1 }"
          >
            <ol class="pt-collapsible__reports">
              <li v-for="(report, index) in reports" :key="index">
                <nuxt-link :to="`/${report.category.section}/${report.slug}`">
                  <icon name="pt-kpi-market-information" class="mr-2" />
                  <span class="font-semibold">{{
                    `${firstLetterUppercase(dateWithMonthAndYear(report.published_date))} - `
                  }}</span>
                  <span>{{ report.title }}</span>
                </nuxt-link>
              </li>
            </ol>
          </dd>
        </div>
      </dl>
    </div>
  </client-only>
</template>
<script>
import { dateWithMonthAndYear } from '~/utils/helpers/date';
import { firstLetterUppercase } from '@/utils/helpers/string';

export default {
  name: 'ReportVersions',
  props: {
    reports: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expandedId: undefined,
      dateWithMonthAndYear,
      firstLetterUppercase
    };
  },
  created() {
    if (this.open) this.expandedId = 1;
  },
  methods: {
    toggleState() {
      return this.expandedId === 1 ? (this.expandedId = undefined) : (this.expandedId = 1);
    }
  }
};
</script>
<style src="../style.scss" lang="scss" />
