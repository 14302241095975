<template>
  <ArticleDetail
    :article="article"
    :articles-interest="articlesInterest"
    :share-networks="shareNetworks"
    :url-share="urlShare"
    :breadcrumbs="breadcrumbs"
    :content-text-share="contentTextShare"
    :report-versions="reportVersions"
    :loading="loading"
    :report-version-open="true"
  />
</template>
<script>
import ArticleDetail from '~/components/Details/Article';
import { STRUCTURED_DATA_TYPE } from '@/utils/enums';

export default {
  name: 'PtDetailArticleSlugMercados',
  components: { ArticleDetail },
  async setup() {
    const {
      loading,
      urlShare,
      breadcrumbs,
      shareNetworks,
      verifySection,
      reportVersions,
      articlesInterest,
      contentTextShare
    } = useArticleDetail();
    const { createHeadSeo } = useHeadSeo();
    const route = useRoute();
    const store = useStore();
    const data = await store.dispatch('strapi/getArticleBySlug', { slug: route.params.slug });
    const article = computed(() => data);
    watch(
      article,
      val => {
        verifySection(val);
        const { meta_title: metaTitle, abstract, main_image: mainImage, og_image: ogImage } = val;
        const imgUrl = ogImage?.url ?? mainImage.url;
        createHeadSeo(metaTitle, abstract, imgUrl, val, STRUCTURED_DATA_TYPE.ARTICLE);
      },
      { immediate: true, deep: true }
    );
    return {
      loading,
      article,
      urlShare,
      breadcrumbs,
      shareNetworks,
      reportVersions,
      articlesInterest,
      contentTextShare
    };
  }
};
</script>
