<template>
  <div v-if="!loading">
    <div class="container relative py-6 md:py-10">
      <div class="grid grid-cols-12">
        <div class="grid grid-cols-12 gap-4 col-span-12">
          <client-only>
            <div v-if="breadcrumbs && !modePost" class="col-span-12">
              <Breadcrumbs
                :data="article.category"
                :path="breadcrumbs.path"
                :section="breadcrumbs.section"
                :show-category="showCategory"
              />
            </div>
          </client-only>
          <div
            :class="[
              'col-span-12 lg:col-span-8',
              { 'lg:col-start-2': article?.icons?.pt_icons || modePost }
            ]"
          >
            <div class="flex justify-between lg:justify-end my-5 space-x-2.5 lg:space-x-0">
              <ul v-if="article?.icons?.pt_icons" class="pt-icons__horizontal">
                <li
                  v-for="(icon, index) in article.icons.pt_icons"
                  :key="icon.id"
                  :class="{ 'ml-4': index }"
                >
                  <icon :name="icon.slug" :title="icon.label" class="stamp" />
                </li>
              </ul>
              <div class="flex flex-none justify-end text-sm text-white">
                <span
                  class="flex w-auto items-center self-baseline px-1 py-px font-semibold rounded bg-gray"
                >
                  {{ readingTime(article?.content || '') }}
                  <icon name="pt-book-open" role="presentation" class="ml-2" />
                </span>
              </div>
            </div>
            <MainContent
              class="mb-6 lg:mb-12"
              :title="article?.title ?? article.meta_title"
              :summary="article?.summary"
            >
              <template #labels>
                <div v-if="notificationReport > 0" class="pt-notification-reports">
                  <icon :name="'pt-info-circle'" class="text-xl text-blue mr-2" />
                  <p class="font-sans text-lg">
                    {{ $t('detail.notificationReport') }}
                    <nuxt-link
                      class="font-bold underline"
                      :to="`/${listReports[0].category.section}/${listReports[0].slug}`"
                      @click="
                        notificationReport = 0;
                        gtmAdsClick(advertising, 'article');
                      "
                    >
                      {{ $t('detail.seeHere') }}
                    </nuxt-link>
                  </p>
                </div>
                <span v-if="article?.published_date" class="pt-published">
                  {{ dateToStringWithNameMonth(article.published_date) }}
                </span>
                <Labels
                  v-if="article?.tags?.length"
                  label-type="transparent"
                  :items="article.tags"
                  to-search
                />
              </template>
            </MainContent>
            <div v-if="!lg" class="flex flex-wrap mt-6 items-center w-full gap-6 mb-6 lg:mb-0">
              <client-only>
                <Share
                  v-if="shareNetworks?.length"
                  class="w-5/12 flex justify-center flex-auto bg-white-dark"
                  :share-networks="shareNetworks"
                  :url="urlShare"
                  :title="article.title"
                  :description="article.summary"
                  :content-text="contentTextShare"
                />
                <Bookmark
                  v-if="$route.name !== PRESENT_SLUG"
                  class="w-5/12 flex-auto"
                  button="block"
                  :entity-id="article.id"
                  :entity-name="entityName"
                  :favorite-entities="userFavorites"
                />
                <DownloadBook
                  v-if="article?.mediateca?.length"
                  class="w-full max-w-xs mx-auto"
                  :image="article.mediateca[0].cover_image"
                  :text="article.mediateca[0].title"
                  :filename="
                    article.mediateca[0].path
                      ? article.mediateca[0].path
                      : article.mediateca[0].filename
                  "
                />
              </client-only>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-10 gap-4 col-span-12 lg:col-span-10">
          <div v-if="article?.icons || modePost" class="pt-icons__vertical">
            <ul v-if="article?.icons?.pt_icons">
              <li v-for="icon in article.icons.pt_icons" :key="icon.id" class="mb-4">
                <icon :name="icon.slug" :title="icon.label" class="stamp" />
              </li>
            </ul>
          </div>
          <div class="col-span-12 lg:col-span-8">
            <div class="pt-main-wrapper">
              <Gallery v-if="article?.gallery?.length" class="mb-4" :images="article.gallery" />
              <a
                v-if="article?.video_relation?.video"
                href="#"
                aria-hidden="true"
                @click.stop.prevent="playVideo(article.video_relation.video.url)"
              >
                <NuxtImg
                  :key="article.video_relation.video.media.url"
                  :alt="article.video_relation.video.media.alternativeText"
                  :src="article.video_relation.video.media.url"
                  loading="lazy"
                  class="pt-main-image"
                />
              </a>
              <NuxtImg
                v-else-if="
                  article?.main_image?.url && (!article?.gallery || article?.gallery?.length <= 0)
                "
                :key="article.id"
                :src="article.main_image.url"
                :alt="
                  article.main_image.alternativeText
                    ? article.main_image.alternativeText
                    : 'articulo'
                "
                class="pt-main-image"
                loading="lazy"
              />
            </div>
            <Authors
              v-if="article?.coordinator_role?.length"
              url-sub-domain="autor"
              :list="article.coordinator_role"
            />
            <Authors
              v-if="article?.author_role?.length"
              url-sub-domain="autor"
              :list="article.author_role"
            />
            <Authors
              v-if="article?.collaborator_roles?.length"
              url-sub-domain="autor"
              :list="article.collaborator_roles"
            />

            <DownloadMaterialDocuments
              v-if="modePost && article?.files?.length"
              :documents="article.files"
            />
            <hr v-if="!article?.files?.length" class="mt-8 mb-6 md:mb-10" />
            <span v-if="article.published_date" class="pt-published">
              {{ dateToStringWithNameMonth(article.published_date) }}
            </span>
            <markdown
              v-if="article?.content"
              class="mt-5 overflow-x-auto"
              :content="article.content || ''"
            />
            <client-only>
              <private-content
                v-if="article?.private_content?.length"
                :content="article.private_content[0]"
                class="mt-12"
              />
              <hr class="my-8 lg:my-12" />
              <Labels
                v-if="article?.tags?.length"
                label-type="transparent"
                :items="article.tags"
                to-search
              />
              <report-versions
                v-if="listReports?.length"
                :reports="listReports"
                :open="reportVersionOpen"
              />
              <div v-if="showLicense" class="my-4">
                <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
                  <NuxtImg
                    :alt="$t('detail.creativeCommons')"
                    style="border-width: 0"
                    src="https://i.creativecommons.org/l/by/4.0/88x31.png"
                    loading="lazy"
                  />
                </a>
                <br />
                {{ $t('detail.licenseUnder') }}
                <a rel="license" href="http://creativecommons.org/licenses/by/4.0/">
                  {{ $t('detail.creativeCommons') }}
                </a>
              </div>
              <!-- Comments -->
              <comment-embed :id="article.slug" class="mt-6" />
            </client-only>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-2">
          <client-only>
            <div class="hidden lg:block">
              <Share
                v-if="shareNetworks?.length"
                :share-networks="shareNetworks"
                :url="urlShare"
                :title="article.title ? article.title : article.meta_title"
                :description="article.summary"
                :content-text="contentTextShare"
              />
              <div class="mt-4">
                <Bookmark
                  v-if="$route.name !== PRESENT_SLUG"
                  button="block"
                  :entity-id="article.id"
                  :entity-name="entityName"
                  :favorite-entities="userFavorites"
                />
              </div>
            </div>
            <hr v-if="article?.mediateca?.length" class="hidden my-8 lg:block" />
            <DownloadBook
              v-if="article?.mediateca?.length && lg"
              class="hidden lg:block"
              :image="article.mediateca[0].cover_image"
              :text="article.mediateca[0].title"
              :filename="
                article.mediateca[0].path
                  ? article.mediateca[0].path
                  : article.mediateca[0].filename
              "
            />
          </client-only>
          <hr v-if="articlesRelated?.length" class="hidden my-8 md:block" />
          <div v-if="articlesRelated?.length" class="hidden lg:block">
            <span class="mb-6 text-base font-bold">
              {{ $t('detail.contentRelational') }}
            </span>
            <Article
              v-for="item in articlesRelated"
              :key="item.slug"
              size="xs"
              dir="col"
              :slug="modePost ? `${$route.params.blog}/${item.slug}` : item.slug"
              :title="modePost ? item.title : item.meta_title"
              :image="item.main_image ? item.main_image : NOT_IMAGE"
              class-title="text-sm font-semibold"
              class="col-span-12 mt-6 md:col-span-3 contentDetail"
              :section="modePost ? COMMUNITY_BASE_URL : item.category?.section"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="communityTexts?.disclaimer"
      class="container grid grid-cols-1 lg:grid-cols-12 gap-3 mb-7"
    >
      <span
        class="col-span-1 lg:col-span-2 text-center lg:text-right text-gray-light leading-5.5"
        v-text="$t('community.downloadDisclaimer')"
      />
      <markdown
        class="pt-disclaimer col-span-1 lg:col-span-10"
        :content="communityTexts?.disclaimer || ''"
      />
    </div>
    <client-only>
      <Section
        v-if="articlesInterest?.length"
        class="bg-orange-very-light"
        :title="$t('detail.interest')"
        grid="E"
      >
        <template #content>
          <div class="swiper grid grid-flow-col-dense lg:grid-flow-row lg:grid-cols-12 gap-6">
            <Article
              v-for="(item, index) in articlesInterest"
              :id="item.id ? item.id : index"
              :key="item.slug"
              size="xs"
              dir="col"
              :slug="item.slug"
              :title="item.meta_title"
              :image="item.image ?? item.main_image"
              :description="item.abstract"
              class-title="text-lg font-bold leading-6"
              class="w-screen lg:w-auto max-w-[288px] col-span-12 sm:col-span-6 md:col-span-3 rounded-sm overflow-hidden bg-white"
              :section="item.category?.section"
              :icons="item.icons"
              class-content="content-min-height px-5 pb-5"
              icon-position="middle"
            />
          </div>
        </template>
      </Section>
    </client-only>
    <Player ref="videoPlayer" />
  </div>
  <div v-else class="w-full text-center py-14">
    <icon name="spinner" class="text-4xl animate-spin text-blue" />
  </div>
</template>

<script>
/* eslint-disable max-lines */
import FavoriteEntities from '~/mixins/FavoriteEntities';
import { dateToStringWithNameMonth } from '~/utils/helpers/date';
import {
  NOT_IMAGE,
  PRESENT_SLUG,
  COMMUNITY_BASE_URL,
  NEWSROOMS_BASE_URL,
  INNOVATION_PAGE,
  MARKET_BASE_URL
} from '~/utils/enums';
import Share from '@/components/Share';
import Labels from '@/components/Labels';
import Authors from '@/components/Authors';
import Gallery from '@/components/Gallery';
import Section from '@/components/Sections';
import Icon from '@/components/Icon';
import Bookmark from '@/components/Bookmark';
import Markdown from '@/components/Markdown';
import Article from '@/components/Cards/Article';
import Breadcrumbs from '@/components/Breadcrumbs';
import Player from '@/components/VideoLibrary/Player';
import CommentEmbed from '@/components/Comment/Embed';
import MainContent from '@/components/Summary/MainContent';
import DownloadBook from '@/components/Summary/DownloadBook';
import PrivateContent from '@/components/Banners/PrivateContent';
import ReportVersions from '@/components/Collapsible/ReportVersions';

export default {
  name: 'PtArticleDetail',
  components: {
    Icon,
    Share,
    Labels,
    Player,
    Gallery,
    Article,
    Authors,
    Section,
    Bookmark,
    Markdown,
    MainContent,
    Breadcrumbs,
    DownloadBook,
    CommentEmbed,
    PrivateContent,
    ReportVersions
  },
  mixins: [FavoriteEntities],
  props: {
    article: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    articlesInterest: {
      type: Array,
      default: () => []
    },
    shareNetworks: {
      type: Array,
      default: () => []
    },
    urlShare: {
      type: String,
      default: ''
    },
    entityName: {
      type: String,
      default: 'article'
    },
    showButtonSave: {
      type: Boolean,
      default: true
    },
    breadcrumbs: {
      type: Object,
      default: () => ({})
    },
    showCategory: {
      type: Boolean,
      default: true
    },
    modePost: {
      type: Boolean,
      default: false
    },
    contentTextShare: {
      type: Object,
      default: () => ({ title: '', description: '' })
    },
    reportVersions: {
      type: Array,
      default: () => []
    },
    communityTexts: {
      type: Object,
      default: () => {}
    },
    reportVersionOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const route = useRoute();
    const { lg } = useBreakpoints();
    const { gtmAdsClick } = useGtmEvents();
    const state = reactive({
      listReports: [],
      articles: [],
      notificationReport: null
    });
    const { listReports, articles, notificationReport } = toRefs(state);
    const store = useStore();
    const videoPlayer = ref(null);
    const playVideo = url => {
      videoPlayer.value?.openPlayer(url);
    };

    const readingTime = text => {
      const wordsPerMinute = 200;
      const noOfWords = text.split(/\s/g).length;
      const minutes = noOfWords / wordsPerMinute;
      const readTime = Math.ceil(minutes);
      return `${readTime} min`;
    };

    const activatedNotification = () => {
      watchEffect(() => {
        if (props?.reportVersions?.length) {
          const slug = route.params.slug;
          const index = props.reportVersions.findIndex(report => report.slug === slug);
          notificationReport.value = index;
          listReports.value = props.reportVersions.filter(report => report.slug !== slug);
        }
      });
    };

    watch(
      () => props?.article,
      () => {
        articles.value = props.modePost
          ? props?.article?.related_posts
          : props?.article?.related_articles;
        activatedNotification();
      },
      { immediate: true }
    );

    const articlesRelated = computed(() =>
      articles?.value?.length
        ? articles?.value.sort((a, b) => b?.published_date?.localeCompare(a?.published_date)) || []
        : []
    );
    const showLicense = computed(
      () =>
        route?.path?.includes(INNOVATION_PAGE) ||
        route?.path?.includes(NEWSROOMS_BASE_URL) ||
        route?.path?.includes(COMMUNITY_BASE_URL) ||
        route?.path?.includes(MARKET_BASE_URL)
    );

    const articleInter = () => {
      store.dispatch('strapi/getArticlesCategorySlug', {
        category: props?.article?.category?.slug,
        slug: route.params.slug,
        limit: 4
      });
    };

    onMounted(() => {
      activatedNotification();
      articleInter();
    });

    return {
      lg,
      dateToStringWithNameMonth,
      readingTime,
      activatedNotification,
      NOT_IMAGE,
      COMMUNITY_BASE_URL,
      PRESENT_SLUG,
      listReports,
      articles,
      notificationReport,
      articlesRelated,
      showLicense,
      playVideo,
      videoPlayer,
      articleInter,
      gtmAdsClick
    };
  }
};
</script>
<style src="./style.scss" lang="scss" scoped />
