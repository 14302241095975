<template>
  <div class="pt-download-documents">
    <p class="pt-download-documents__title">{{ $t('community.documents') }}</p>

    <div class="pt-download-documents__group">
      <div v-for="(document, index) in documents" :key="index">
        <button
          v-if="document?.document"
          class="pt-download-documents__button"
          type="button"
          @click="downloadDocument(document)"
        >
          <icon name="pt-PDFnoun" class="text-red text-2xl mr-2.5" />
          <span class="underline">{{ document?.document?.title }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadFileCrossBrowser } from '@/utils/helpers/script';

export default {
  name: 'PtDownloadDocuments',
  props: {
    documents: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    downloadDocument(document) {
      downloadFileCrossBrowser(document?.document?.file?.url);
    }
  }
};
</script>
<style src="./style.scss" lang="scss" scoped />
