<template>
  <ArticleDetail
    v-if="!loading"
    :article="article"
    :articles-interest="articlesInterest"
    :share-networks="shareNetworks"
    :url-share="urlShare"
    :breadcrumbs="breadcrumbs"
    :content-text-share="contentTextShare"
    :report-versions="reportVersions"
    :loading="loading"
  />
</template>
<script>
import ArticleDetail from '~/components/Details/Article';
import { STRUCTURED_DATA_TYPE } from '@/utils/enums';

export default {
  name: 'PtDetailArticleSlugInnovacion',
  components: { ArticleDetail },
  async setup() {
    const {
      urlShare,
      breadcrumbs,
      shareNetworks,
      reportVersions,
      articlesInterest,
      contentTextShare,
      verifySection,
      loading
    } = useArticleDetail();
    const { createHeadSeo } = useHeadSeo();
    const route = useRoute();
    const store = useStore();
    const data = await store.dispatch('strapi/getArticleBySlug', { slug: route.params.slug });
    verifySection(data);
    const article = computed(() => data);
    watch(
      article,
      val => {
        const { meta_title: metaTitle, abstract, main_image: mainImage, og_image: ogImage } = val;
        const imgUrl = ogImage?.url ?? mainImage.url;
        val?.mediateca?.length
          ? createHeadSeo(metaTitle, abstract, imgUrl, val, STRUCTURED_DATA_TYPE.BOOKINNOVATION)
          : createHeadSeo(metaTitle, abstract, imgUrl, val, STRUCTURED_DATA_TYPE.INNOVATION);
      },
      { immediate: true, deep: true }
    );
    return {
      loading,
      article,
      urlShare,
      breadcrumbs,
      shareNetworks,
      reportVersions,
      articlesInterest,
      contentTextShare
    };
  }
};
</script>
